@charset "UTF-8";
:root {
  --active-sidebar: #fff700;
}

html {
  -webkit-tap-highlight-color: transparent;
  height: -webkit-fill-available;
  min-height: 100vh;
}

html,
body {
  height: 100%;
  width: 100vw;
}

body {
  margin: 0;
}
@media (max-width: 992px) {
  body {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }
}

@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
}
main {
  flex: 1;
}

#map {
  --heightHeader: calc(var(--header-height, 76px) + var(--custom-info-height));
  position: fixed;
  top: calc(var(--header-height) + var(--custom-info-height));
  bottom: 0;
  left: 0;
  right: 0;
  min-height: calc(100% - var(--heightHeader, 76px));
  height: calc(100% - var(--heightHeader, 76px));
  background: var(--bs-white);
}
@media (max-width: 991px) {
  #map {
    --heightHeader: calc(
      var(--header-height) + var(--watch-partner, 0px) +
        var(--custom-info-height)
    );
  }
}
@media (max-width: 991px) {
  #map {
    top: auto;
  }
}

@media (max-width: 991px) {
  .bg-dark {
    display: none;
  }
}
.map-container {
  overflow: hidden;
}

.maptiler-element {
  position: absolute;
  left: 20px;
  bottom: 5px;
  z-index: 999;
}

.leaflet-control-attribution a svg {
  display: none !important;
}

.leaflet-control-container .leaflet-top,
.leaflet-control-container .leaflet-bottom {
  will-change: transform;
}

.icon {
  display: flex;
  border-radius: 50%;
  background-color: var(--bs-white);
}

.leaflet-marker-icon span {
  font-family: "Oswald", sans-serif;
}

.icon-flights {
  border: 2px solid var(--bs-white);
}

@keyframes border-pulse {
  0% {
    box-shadow: 0 0 0 0 rgb(255, 255, 255);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(255, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}
.icon-flights.animation {
  animation: border-pulse 1.5s infinite;
}

.icon-current {
  border: 2px solid var(--bs-white);
  animation: border-pulse 1.5s infinite;
}

.information-row {
  cursor: pointer;
  gap: 10px;
  padding: 10px;
}
.information-row .icon {
  width: 35px;
  height: 35px;
  border: 1px solid var(--bs-gray-400);
  transition: border 0.5s ease-out;
}
.information-row:hover {
  background-color: var(--bs-gray-100);
}
.information-row:hover .icon {
  box-shadow: inset 0px 0px 13px -5px rgb(139, 220, 255);
  border: 1px solid var(--bs-blue);
}
.information-row:hover .information-row-name {
  color: var(--bs-gray-900);
}

.leaflet-marker-icon:not(.leaflet-control-locate-heading) svg {
  width: 24px;
  height: 24px;
}

.information-row-icon svg {
  width: 24px;
  height: 24px;
}

.icon-accessible-toilet > * {
  border: 1px solid var(--bs-white);
}

.leaflet-marker-icon:not(.leaflet-control-locate-location) {
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.icon-twitter svg {
  width: 20px !important;
  height: 20px !important;
}

.leaflet-control-locate-heading {
  border: 0 !important;
  box-shadow: none !important;
}

.sidebar {
  position: fixed;
  bottom: 0;
  z-index: 1002;
  width: 100%;
  max-width: 500px;
  margin-bottom: 5px;
  border-radius: 10px;
  background-color: rgba(57, 57, 57, 0.8);
  line-height: 80%;
  text-align: center;
}
@media (max-width: 430px) {
  .sidebar .menu-text {
    font-size: 9px;
  }
}
@media (max-width: 600px) {
  .sidebar {
    max-width: 100%;
    border-radius: 0;
    margin-bottom: 0;
    left: 0;
  }
}
.sidebar .sidebar-location {
  display: var(--has-touch-screen);
}

.menu-item {
  user-select: none;
  display: flex;
  cursor: pointer;
  padding: 10px;
  color: var(--bs-white);
  align-items: center;
  font-size: 80%;
  align-self: center;
  transition: color 0.5s ease-out;
}
@media (max-width: 430px) {
  .menu-item {
    padding: 5px;
  }
}
.menu-item svg {
  pointer-events: none;
  width: 35px;
  height: 35px;
  fill: var(--bs-white);
  transition: fill 0.5s ease-out;
}
@media (max-width: 450px) {
  .menu-item svg {
    width: 25px;
    height: 25px;
  }
}
@media (hover: hover) {
  .menu-item:hover svg {
    transition: fill 0.4s ease-in;
    fill: var(--bs-warning);
  }
  .menu-item:hover .menu-text {
    transition: color 0.4s ease-in;
    color: var(--bs-warning);
  }
}

.menu-text {
  word-wrap: break-word;
}

.fullscreen-is-active {
  overflow: hidden;
}
.fullscreen-is-active .sidebar-fullscreen-exit {
  display: flex;
}
@media (max-width: 992px) {
  .fullscreen-is-active .sidebar-fullscreen-exit {
    display: none;
  }
}
.fullscreen-is-active .sidebar-fullscreen-in {
  display: none;
}
.fullscreen-is-active #map {
  position: fixed;
  width: 100%;
  border: 0;
  height: 100%;
  z-index: 9;
}
@media (max-width: 992px) {
  .fullscreen-is-active #map {
    height: calc(100vh - 50px);
  }
}
@media (max-width: 991px) {
  .fullscreen-is-active .navbar {
    z-index: 999;
  }
}
@media (min-width: 992px) {
  .fullscreen-is-active .navbar {
    z-index: 0;
  }
}
.fullscreen-is-active .sidebar {
  position: fixed;
}
.fullscreen-is-active .maptiler-element {
  position: fixed;
  left: 10px;
  bottom: 5px;
}

.location-is-active .sidebar-location svg {
  fill: var(--bs-blue);
}
.location-is-active .sidebar-location .menu-text {
  color: var(--active-sidebar);
}

.parnter-quickbar-slider img {
  max-height: 38px;
}

.slider-wrapper-content {
  padding-right: 40px;
}

.slider-title {
  position: relative;
  border-radius: 0 10px 0 0;
  background-color: var(--main-color-bg, #393939);
  color: var(--bs-white);
  font-size: 14px;
  height: 60px;
  display: flex;
  align-items: center;
}
@media (max-width: 600px) {
  .slider-title {
    border-radius: 0;
  }
}

.close-slider {
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
}
.close-slider .icon {
  pointer-events: none;
  background: none;
  width: 30px;
  height: 30px;
  fill: var(--bs-white);
}

.slider-content {
  overflow: auto;
  height: calc(100% - var(--sidebar-height));
}

.button-close-slider {
  position: absolute;
  width: 30px;
  right: 5px;
  z-index: 999;
}

.title-slider {
  position: absolute;
  background: var(--main-color-bg);
  top: -26px;
  left: 0;
  width: auto;
  padding: 5px 15px 0 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.parnter-quickbar-slider {
  margin-left: auto;
  margin-right: 0;
}

.logo-slider img {
  max-height: 38px;
}

.logo-slider img {
  max-height: 60px;
  max-width: 120px;
}

.leaflet-popup-content-wrapper {
  max-width: 320px;
}

.popup-content h3 {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
}
.popup-content tbody th {
  font-weight: normal;
}
.popup-content .table > :not(caption) > * > * {
  padding: 0.1rem 0.3rem;
}
.popup-content thead > tr > :nth-child(1),
.popup-content tbody > tr > :nth-child(1) {
  padding: 0.1rem 0rem;
}
.popup-content .top-five-country span {
  width: 15px;
  height: 15px;
}

.leaflet-popup p {
  margin: 0;
}
.leaflet-popup .card {
  border: 0;
}
.leaflet-popup .card-title {
  font-size: 1rem;
  font-weight: bold;
}
.leaflet-popup .popup-content h4 {
  font-size: 0.9rem;
  font-weight: bold;
}
.leaflet-popup .card-text {
  font-size: 100%;
}
.leaflet-popup table {
  margin-bottom: 0 !important;
}
.leaflet-popup .player-teetime {
  line-height: 120%;
}

.leaflet-popup-content {
  margin: 13px 24px 13px 18px !important;
}

.leaflet-popup-content:has(.card-img), .leaflet-popup-content:has(.card-video), .leaflet-popup-content:has(.social-links-wrappers) {
  margin: 13px 18px !important;
}

.group-popup {
  font-family: "Oswald", sans-serif;
}
.group-popup .leaflet-popup-content {
  min-width: 240px !important;
  max-width: 240px !important;
}

.leaflet-popup:not(.group-popup) {
  font-family: "Inter", sans-serif !important;
}

.leaflet-popup-content {
  margin: 13px 18px;
}

.card-img-top {
  margin-top: -15px;
  margin-left: -19px;
  margin-right: -19px;
  min-width: 320px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.card-img {
  margin-top: -14px;
  margin-left: -19px;
  margin-right: -19px;
  min-width: 320px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  height: 180px;
  background-repeat: no-repeat !important;
  background-position: 100% 100% !important;
  background-size: 100% 100% !important;
}

.card-video {
  margin-top: -14px;
  margin-left: -19px;
  margin-right: -19px;
  min-width: 320px;
}
.card-video video {
  min-width: 100%;
  min-height: 180px;
  object-fit: fill;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.leaflet-container a.leaflet-popup-close-button {
  background-color: #fff;
  border-radius: 12px;
}

.sidebar-flags svg {
  fill: var(--active-sidebar);
}
.sidebar-flags .menu-text {
  color: var(--active-sidebar);
}

.flags-is-removed .sidebar-flags svg {
  fill: var(--bs-white);
}
.flags-is-removed .sidebar-flags .menu-text {
  color: var(--bs-white);
}

.favorite {
  display: none;
}

.favorite-player-on-map {
  background-color: #f7a001 !important;
}
.favorite-player-on-map svg {
  fill: var(--bs-white);
}
.favorite-player-on-map .first {
  display: none;
}
.favorite-player-on-map .favorite {
  display: block;
}

.location-button, .back-to-home,
.show-multi-course-layers {
  display: var(--has-touch-screen);
  background: var(--bs-white);
  border: 0;
  margin-top: 0;
  left: 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
}
.location-button svg, .back-to-home svg,
.show-multi-course-layers svg {
  width: 18px;
  height: 18px;
}

.back-to-home,
.show-multi-course-layers {
  display: flex;
}

.slider {
  width: 100%;
  margin: auto;
  position: fixed;
  height: 70%;
  z-index: 1001;
  left: 50%;
  bottom: 0;
  opacity: 0;
  background: var(--bs-white);
  transform: translate(-50%, 100%);
  transition: transform 0.2s linear, opacity 0.2s linear;
  will-change: auto;
}
.slider h3 {
  margin: 0;
}
@media (min-width: 601px) {
  .slider {
    height: 80%;
    max-height: 650px;
    max-width: 500px;
    border-radius: 0 10px 10px 10px;
  }
}

.active-slider {
  opacity: 1;
  transform: translate(-50%, 0);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  will-change: auto;
  bottom: 5px;
}
@media (max-width: 600px) {
  .active-slider {
    min-width: 100%;
    bottom: 0;
  }
}
.active-slider .sidebar-info svg {
  fill: var(--active-sidebar);
}
.active-slider .sidebar-info .menu-text {
  color: var(--active-sidebar);
}

.fullscreen-is-active .map-container {
  position: fixed;
  top: 0;
  height: 100%;
  min-width: 100%;
  z-index: 9;
}

.switch-container {
  display: flex;
  margin-left: auto;
}

.switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}
.switch input {
  display: none;
}

.swich-slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}
.swich-slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}

input:checked + .swich-slider {
  background-color: #66bb6a;
}

input:checked + .swich-slider:before {
  transform: translateX(26px);
}

.swich-slider.round {
  border-radius: 34px;
}
.swich-slider.round:before {
  border-radius: 50%;
}

[data-layer-id=show-all-layers] {
  background-color: var(--bs-gray-200);
  border-bottom: 1px solid var(--bs-gray-300);
}
[data-layer-id=show-all-layers] .information-row-name {
  font-weight: bold;
}

.active-slider-menu svg {
  transition: fill 0.4s ease-in;
  fill: var(--bs-warning);
}
.active-slider-menu .menu-text {
  transition: color 0.4s ease-in;
  color: var(--bs-warning);
}

.round-number {
  margin-left: 5px;
}

.projected-cut {
  margin-left: -12px;
  margin-right: -12px;
  padding-left: 14px;
  background-color: var(--main-color-bg) !important;
  color: var(--bs-white);
}

.slider-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 99999;
  max-width: 500px;
  display: var(--hide-sidebar, block);
}

.leaflet-top.leaflet-right {
  display: var(--hide-sidebar, block);
}

.small-font {
  font-size: 90%;
}

.site-locator .ads-row {
  min-height: 50px;
}

.auto-results-wrapper.auto-is-active {
  position: relative;
  margin-top: 10px;
}

.auto-results-wrapper {
  border: 0;
}

.loupe:before {
  top: 17px;
  bottom: auto;
}

.auto-clear {
  top: 12px;
  bottom: auto;
}

#auto-search-players-results {
  height: var(--slider-height);
}

.players-is-active .slider-content {
  overflow: hidden;
}

.auto-search-wrapper input {
  padding: 10px 12px;
}

.auto-footer {
  position: absolute;
  height: 32px;
  width: 100%;
  top: 0;
  background-color: red;
  color: #fff;
  padding: 5px 10px;
}

.auto-head {
  top: 0;
  text-transform: uppercase;
  align-items: center;
  color: var(--bs-white);
  background-color: var(--main-color-bg);
  font-size: 90%;
}

.auto-pr {
  padding-right: var(--touch-screen-players) !important;
}

.auto-row {
  position: relative;
}
.auto-row .auto-group {
  order: 1;
}
.auto-row .auto-country {
  order: 3;
}
.auto-row .auto-player-name {
  order: 2;
}
.auto-row .auto-tee {
  order: 4;
}
.auto-row .auto-thru {
  order: 5;
}
.auto-row .auto-fav {
  order: 6;
}
.auto-row::before {
  content: "";
  position: absolute;
  top: 0;
  left: 1px;
  width: 5px;
  bottom: 0;
  background-color: var(--course-color);
}

.auto-search-wrapper input {
  margin: 10px auto 0 auto;
  width: calc(100% - 20px);
  display: flex;
}

.auto-clear {
  top: 11px;
  right: 8px;
}

.auto-search-wrapper ul li {
  padding: 0;
  font-size: 90%;
}

.auto-search-wrapper .border-top {
  border-color: var(--bs-gray-400) !important;
}

.prevent-close {
  cursor: auto !important;
}

[data-id-flightnumber] {
  cursor: pointer !important;
}

.leaflet-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  right: 0;
}

.multi-course-layers {
  display: flex;
  max-width: 600px;
  margin: 20px auto 0;
  background: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.multi-course-wrapper {
  display: flex;
  flex-flow: row wrap;
  gap: 15px;
  margin: auto;
  padding: 0 50px;
  justify-content: center;
  pointer-events: none;
}
@media (max-width: 730px) {
  .multi-course-wrapper {
    flex-direction: column;
    gap: 0px;
  }
}

.button-layer {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.8);
  padding: 5px 10px;
  color: #000;
  justify-content: center;
  font-size: 14px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  border-left: 1px solid var(--color-button-layer);
  border-left-width: 30px;
  transition: all 0.3s ease;
}
.button-layer:hover {
  background: #fff;
}

.boda-box {
  cursor: pointer;
  height: 100%;
  left: 0;
  border: 20px solid red;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: opacity 0.5s ease-in-out;
  width: 100%;
  z-index: 999;
}

.accordion {
  width: 100%;
  background-color: var(--bs-white);
  border: none;
  outline: none;
  text-align: left;
  padding: 15px 10px;
  font-size: 18px;
  color: var(--bs-blue);
  border-bottom: 1px solid var(--bs-gray-500);
  cursor: pointer;
  transition: background-color 0.2s linear;
}

.accordion:after {
  content: "↓";
  float: right;
}

.accordion.is-open:after {
  content: "↑";
}

.accordion:hover,
.accordion.is-open {
  background-color: var(--bs-gray-200);
}

.accordion-content {
  background-color: var(--bs-white);
  border-left: 1px solid whitesmoke;
  border-right: 1px solid whitesmoke;
  padding: 0 10px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}

.accortion-list {
  margin-left: -13px;
}

.accortion-list svg {
  width: 16px;
  height: 16px;
  margin-bottom: 4px;
  fill: var(--bs-gray-700);
}

.popup-info {
  position: fixed;
  background: var(--bs-gray-100);
  color: var(--bs-gray-900);
  width: calc(100% - 20px);
  height: max-content;
  max-width: 500px;
  top: 0;
  bottom: 0;
  left: 10px;
  right: 10px;
  z-index: 1;
  margin: auto;
  opacity: 0;
  border-radius: 5px;
  padding: 10px;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
}

.active-popup .popup-info {
  opacity: 1;
  pointer-events: auto;
  z-index: 999;
}

.popup-info .popup-header {
  font-weight: 600;
  margin-bottom: 10px;
}
.popup-info .popup-close {
  position: absolute;
  top: 4px;
  right: 5px;
  padding: 0;
  border: 0;
  background: transparent;
}
.popup-info .popup-close .icon {
  pointer-events: none;
  background: none;
  width: 30px;
  height: 30px;
  fill: var(--bs-gray-900);
}

.holes-played {
  margin-right: 15px;
}

.highlights-logo {
  margin-right: -10px;
}
.highlights-logo img {
  display: block;
  margin: 2px auto 4px;
  max-height: 50px;
  border: 1px solid var(--bs-gray-300);
}
.highlights-logo svg {
  width: 25px;
  height: 25px;
  margin: auto;
  display: block;
}

.highlights-row {
  font-size: 90%;
  align-items: center;
}

.highlights-row:hover,
.program-row:hover:not([data-show-on-map=false]) {
  cursor: pointer;
  background-color: var(--bs-gray-200);
}

.highlights-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card-title-wrapper {
  position: relative;
}
.card-title-wrapper h3 {
  margin-top: 6px;
}

.social-links-wrappers {
  cursor: pointer;
}

.social-links-highlights {
  width: 100%;
  height: 30px;
  justify-content: space-between;
  align-items: center;
  margin-right: 15px;
  left: 0;
  z-index: 5;
  padding-right: 45px;
  background-color: #fff;
}
.social-links-highlights svg {
  width: 24px;
  height: 24px;
  fill: #888;
  transition: fill 500ms linear;
}
.social-links-highlights svg:hover {
  fill: #3f3f3f;
}

.copy-link {
  cursor: pointer;
}

.h-copy svg {
  pointer-events: none;
}

.share-content {
  z-index: 9;
  height: 30px;
}
.share-content svg {
  pointer-events: none;
  width: 30px;
  height: 30px;
  fill: #888;
}

.player-on-range {
  max-height: 150px;
  overflow: auto;
}
.player-on-range img,
.player-on-range svg {
  height: 25px;
}

.hole-info {
  color: #192267;
}
.hole-info-par strong {
  font-size: 0.9rem;
}
.hole-info-avg {
  color: var(--bs-gray-500);
}

.card-leaflet {
  box-shadow: none;
  width: 100%;
}
@media (min-width: 768px) {
  .card-leaflet {
    max-width: 300px;
  }
}
.card-leaflet .card-body {
  padding: 0;
}
.card-leaflet .card-body p {
  margin-top: 5px;
}
.card-leaflet .card-body p:empty {
  margin-top: 0;
}
.card-leaflet .card-body a {
  color: #fff;
}
.card-leaflet .card-body video {
  max-width: 300px;
  width: 100%;
  max-height: 170px;
}
.card-leaflet .popup-content {
  font-size: 110%;
  border-top: 1px solid var(--bs-gray-300);
  padding-top: 10px;
}
.card-leaflet .popup-content h4 {
  text-transform: uppercase;
}
.card-leaflet .popup-content thead th {
  font-weight: bold;
}
.card-leaflet .popup-content .top-five-country span {
  width: 19px;
  height: 19px;
}

.card-twitter a {
  color: #1d9bf0 !important;
  text-decoration: none;
}
.card-twitter a:hover {
  text-decoration: underline;
}
.card-twitter .title {
  padding-top: 3px;
  margin-left: -10px;
}
.card-twitter .logo {
  border-radius: 50%;
}
.card-twitter .video {
  max-width: 300px;
  width: 100%;
}
.card-twitter .social-links-highlights {
  min-width: 250px;
  margin-top: 12px;
  margin-bottom: -3px;
}

.twitter-logo {
  margin: -15px 0 -10px;
}

.card-button {
  margin-top: 5px;
  padding: 10px 15px;
  text-decoration: none;
  border-radius: 11px;
  font-size: 1rem;
  line-height: 100%;
  background-color: var(--card-color-back, #000);
  color: var(--card-color-text, #fff) !important;
  transition: box-shadow ease 0.5s;
}
.card-button:hover {
  box-shadow: 0px 10px 12px -11px rgb(66, 68, 90);
}

.card-img-wrapper {
  max-height: 180px;
  position: relative;
}
.card-img-wrapper .card-button {
  position: absolute;
  bottom: 10px;
  right: -10px;
}

.programme-header {
  background-color: #c1c1c1 !important;
  color: var(--bs-white);
  text-align: center;
}

.callendar-button {
  max-width: 30px;
  margin-left: auto;
  cursor: pointer;
}
.callendar-button svg {
  border: 0;
  width: 30px;
  height: 30px;
  fill: gray;
}

@media (max-width: 480px) {
  .column-time {
    padding: 0 5px;
  }
}

.parnter-logo {
  text-align: center;
}
@media (max-width: 480px) {
  .parnter-logo {
    padding: 0;
  }
}
.parnter-logo img {
  max-height: 41px;
}
@media (max-width: 460px) {
  .parnter-logo img {
    max-width: 60px;
  }
}

.programme-wrapper {
  width: 100%;
  vertical-align: baseline;
}

.dev-data-locator {
  font-size: 11px;
  padding: 2px 5px;
  background-color: #fff;
  position: fixed;
  bottom: 2px;
  left: 2px;
  z-index: 999;
}

.leaflet-bottom.leaflet-right {
  bottom: 18px;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
}
@media (max-width: 660px) {
  .leaflet-bottom.leaflet-right {
    bottom: 80px;
  }
}
@media (max-width: 430px) {
  .leaflet-bottom.leaflet-right {
    bottom: 65px;
  }
}

.leaflet-control-attribution {
  visibility: hidden;
  position: fixed;
  flex-wrap: nowrap;
  width: 100%;
  min-width: 300px !important;
  height: auto !important;
  padding: 8px;
  right: 15px;
  z-index: -1;
  padding-right: 30px;
  padding-left: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  align-items: center;
}
@media (max-width: 599px) {
  .leaflet-control-attribution {
    overflow: visible;
  }
}
@media (max-width: 768px) {
  .leaflet-control-attribution {
    max-width: max-content;
  }
}
@media (min-width: 769px) {
  .leaflet-control-attribution {
    max-width: max-content;
  }
}
@media (max-width: 600px) {
  .leaflet-control-attribution {
    flex-wrap: wrap;
    line-height: 100%;
  }
}

.bottom-left-logo {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 9999;
  background: var(--bs-white);
}

.extra-icon {
  cursor: pointer;
}
.extra-icon svg {
  pointer-events: none;
}

.icon-home {
  padding-left: 10px;
}
@media (max-width: 932px) {
  .icon-home {
    padding-left: 0;
  }
}
.icon-home svg {
  display: inline-block;
  fill: rgba(255, 255, 255, 0.5);
  width: 120px;
  height: 30px;
}

@media (max-width: 932px) {
  .eventhub-text {
    display: none;
  }
}
@media (max-width: 600px) {
  .leaflet-bottom .leaflet-control {
    margin-bottom: 0;
  }
}

@media (max-width: 932px) {
  .home-eventhubhq {
    width: 40px;
  }
}

.content-information {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
}
.content-information.leaflet-control {
  margin: 0 6px -5px;
}
.content-information.extra-icon {
  margin: 0;
}

.home-eventhubhq {
  bottom: 10px;
}
@media (max-width: 600px) {
  .home-eventhubhq {
    bottom: 80px;
  }
}
@media (max-width: 430px) {
  .home-eventhubhq {
    bottom: 60px;
  }
}

.icon-infor {
  margin-right: 7px;
}
.icon-infor.information-active svg {
  fill: #333;
}
.icon-infor svg {
  display: inline-block;
  fill: rgba(255, 255, 255, 0.7);
  width: 25px;
  height: 25px;
}

.twitter-tweet {
  margin: -13px auto !important;
  min-height: 250px;
}

blockquote {
  display: none;
}

.leaflet-popup-content:has(.twitter-tweet) {
  --scrollbar-bg: #cfd8dc;
  --thumb-bg: #90a4ae;
  margin: 11px 0 !important;
  overflow-y: auto;
  max-height: 360px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.timeline-wrapper {
  padding: 0px;
  border-radius: 12px;
  margin: 5px;
}

.timeline-item {
  background: #fff;
  border-radius: 3px;
  padding: 12px;
  margin: 0 auto;
  max-width: 472px;
  min-height: 200px;
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #eeeeee;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.background-masker {
  background: #fff;
  position: absolute;
  box-sizing: border-box;
}

.background-masker.header-top,
.background-masker.header-bottom,
.background-masker.subheader-bottom {
  top: 0;
  left: 40px;
  right: 0;
  height: 10px;
}

.background-masker.header-left,
.background-masker.subheader-left,
.background-masker.header-right,
.background-masker.subheader-right {
  top: 10px;
  left: 40px;
  height: 8px;
  width: 10px;
}

.background-masker.header-bottom {
  top: 18px;
  height: 6px;
}

.background-masker.subheader-left,
.background-masker.subheader-right {
  top: 24px;
  height: 6px;
}

.background-masker.header-right,
.background-masker.subheader-right {
  width: auto;
  left: 300px;
  right: 0;
}

.background-masker.subheader-right {
  left: 230px;
}

.background-masker.subheader-bottom {
  top: 30px;
  height: 10px;
}

.background-masker.content-top,
.background-masker.content-second-line,
.background-masker.content-third-line,
.background-masker.content-second-end,
.background-masker.content-third-end,
.background-masker.content-first-end {
  top: 40px;
  left: 0;
  right: 0;
  height: 6px;
}

.background-masker.content-top {
  height: 20px;
}

.background-masker.content-first-end,
.background-masker.content-second-end,
.background-masker.content-third-end {
  width: auto;
  left: 380px;
  right: 0;
  top: 60px;
  height: 8px;
}

.background-masker.content-second-line {
  top: 68px;
}

.background-masker.content-second-end {
  left: 420px;
  top: 74px;
}

.background-masker.content-third-line {
  top: 82px;
}

.background-masker.content-third-end {
  left: 300px;
  top: 88px;
}

.team-selection {
  position: sticky;
  top: 0;
  z-index: 1;
  cursor: pointer;
}

.team-usa {
  background: #c81414;
  color: #fff;
}
.team-usa.is-active {
  background: #a10a0a;
}

.team-eur {
  background: #003c82;
  color: #fff;
}
.team-eur.is-active {
  background: #02245d;
}

.team-points {
  background: var(--team-color);
  color: #fff;
  text-align: center;
  min-width: 55px;
  line-height: 80%;
}

.team-points-usa {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.team-points-eur {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.team-leader-up {
  --team-leader-up: 1.3rem;
  font-size: var(--team-leader-up);
  font-weight: 700;
  white-space: nowrap;
}
@media (max-width: 430px) {
  .team-leader-up {
    --team-leader-up: 1rem;
  }
}

.team-thru {
  --team-leader-thru: 0.9rem;
  font-size: var(--team-leader-thru);
  font-weight: 700;
  color: #b3b3b3;
}
@media (max-width: 430px) {
  .team-thru {
    --team-leader-thru: 0.7rem;
  }
}

.color-standing {
  color: #fff;
  padding: 1px 2px;
  width: 50px;
  margin: auto;
  font-weight: 400;
}

.color-usa {
  background: #c81414;
}

.color-eur {
  background: #003c82;
}

.color-as {
  background: #898989;
}

.color-reverse-usa {
  color: #c81414;
  font-size: 16px;
}

.color-reverse-eur {
  color: #003c82;
  font-weight: bold;
  font-size: 16px;
}

.color-reverse-as {
  color: #898989;
  font-weight: bold;
  font-size: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

.green-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #a3a3a3;
}

.green-dot-active {
  background: greenyellow;
  border: 1px solid #6cb300 !important;
}

.mach-player-image svg {
  width: 50px;
  height: 50px;
}

.mach-player-image img {
  display: block;
  margin: auto;
}

.mach-player-icon svg {
  width: 25px !important;
  height: 25px !important;
}

.flag-icon-match {
  width: 25px;
  height: 25px;
}

.squads-wrapper {
  background-color: rgba(255, 255, 255, 0.8);
  max-width: 255px;
  font-size: 17px;
  font-weight: bold;
  line-height: 100%;
  box-shadow: 0px 9px 15px 0px rgba(66, 68, 90, 0.2);
  margin: auto;
  border-radius: 7px;
}

.team-color {
  color: #383838;
  padding: 2px 9px;
}

.squads-logo {
  order: 2;
  --logo-width: 40;
  --logo-height: 50;
  order: 2;
  margin: -5px -15px -10px;
}
.squads-logo img {
  width: 40px;
  height: 50px;
}

.team-eur-score {
  order: 1;
}

.team-name {
  margin-bottom: -2px;
}

.leaderboards-head {
  background-color: #a3a3a3;
  color: #fff;
}

.flag-eur {
  order: 1;
}
.flag-eur span {
  margin-left: 12px;
}
@media (max-width: 400px) {
  .flag-eur span {
    width: 20px;
    height: 20px;
  }
}

.team-person {
  line-height: 120%;
}
@media (max-width: 460px) {
  .team-person {
    font-size: 0.8rem;
  }
}

.team-lead-eur {
  order: 2;
}

.team-leader {
  order: 3;
}

.team-lead-usa {
  order: 4;
}

.flag-usa {
  order: 5;
}
.flag-usa span {
  margin-right: 4px;
}
@media (max-width: 400px) {
  .flag-usa span {
    width: 20px;
    height: 20px;
  }
}

.center-xy {
  display: flex;
  justify-content: center;
  align-self: center;
}

.mp-head img {
  max-width: 130px;
}

.mp-body {
  font-size: 1.1rem;
}
.mp-body .col-flag {
  padding-right: 0;
  margin-left: -7px;
  margin-bottom: 5px;
}
.mp-body .col-players {
  line-height: 100%;
}
.mp-body .color-standing {
  font-size: 2.5rem;
  letter-spacing: -2px;
}
.mp-body .team-thru {
  font-size: 1.2rem;
}
.mp-body .team-leader-up {
  line-height: 180%;
}

.mp-wrapper {
  font-size: 16px;
}
.mp-wrapper h5 {
  font-weight: 700;
  font-size: 13px;
}

.mp-player {
  margin-bottom: 5px;
}

.mp-round-name {
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  font-weight: 700;
}

.mp-hole {
  text-transform: capitalize;
  margin-top: 3px;
  font-size: 13px;
}

.player-row-information {
  font-size: 12px;
  background: #a3a3a3;
}
.player-row-information svg {
  width: 20px;
  height: 20px;
}
@media (max-width: 576px) {
  .player-row-information .first-section {
    margin-bottom: -7px;
  }
}
.player-row-information .green-dot {
  margin-bottom: 1px;
  width: 15px;
  height: 15px;
}

.player-col {
  display: flex;
  line-height: 100%;
  padding: 7px 0;
  align-items: center;
  gap: 3px;
}
.player-col:nth-child(1) {
  gap: 6px;
}