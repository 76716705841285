.team-selection {
  position: sticky;
  top: 0;
  z-index: 1;
  cursor: pointer;
}

.team-usa {
  background: #c81414;
  color: #fff;
  &.is-active {
    background: #a10a0a;
  }
}

.team-eur {
  background: #003c82;
  color: #fff;
  &.is-active {
    background: #02245d;
  }
}

.team-points {
  background: var(--team-color);
  color: #fff;
  text-align: center;
  min-width: 55px;
  line-height: 80%;
}

.team-points-usa {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.team-points-eur {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.team-leader-up {
  --team-leader-up: 1.3rem;
  font-size: var(--team-leader-up);
  font-weight: 700;
  white-space: nowrap;

  @media (max-width: 430px) {
    --team-leader-up: 1rem;
  }
}

.team-thru {
  --team-leader-thru: 0.9rem;
  font-size: var(--team-leader-thru);
  font-weight: 700;
  color: #b3b3b3;
  // margin-top: -10px;
  @media (max-width: 430px) {
    --team-leader-thru: 0.7rem;
  }
}

.color-standing {
  color: #fff;
  padding: 1px 2px;
  width: 50px;
  margin: auto;
  font-weight: 400;
}

// -----------------

.color-usa {
  background: #c81414;
}

.color-eur {
  background: #003c82;
}

.color-as {
  background: #898989;
}

.color-reverse-usa {
  color: #c81414;
  font-size: 16px;
}

.color-reverse-eur {
  color: #003c82;
  font-weight: bold;
  font-size: 16px;
}

.color-reverse-as {
  color: #898989;
  font-weight: bold;
  font-size: 16px;
}

// -----------------

.cursor-pointer {
  cursor: pointer;
}

.green-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #a3a3a3;
}

.green-dot-active {
  background: greenyellow;
  border: 1px solid #6cb300 !important;
}

.mach-player-image {
  svg {
    width: 50px;
    height: 50px;
  }
}

.mach-player-image {
  img {
    display: block;
    margin: auto;
  }
}

.mach-player-icon svg {
  width: 25px !important;
  height: 25px !important;
}

.flag-icon-match {
  width: 25px;
  height: 25px;
}

.squads-wrapper {
  background-color: rgba(255, 255, 255, 0.8);
  max-width: 255px;
  font-size: 17px;
  font-weight: bold;
  line-height: 100%;
  box-shadow: 0px 9px 15px 0px rgba(66, 68, 90, 0.2);
  margin: auto;
  border-radius: 7px;
}

.team-color {
  color: #383838;
  padding: 2px 9px;
}

.squads-logo {
  order: 2;
  --logo-width: 40;
  --logo-height: 50;

  order: 2;
  margin: -5px -15px -10px;
  img {
    width: 40px;
    height: 50px;
  }
}

.team-eur-score {
  order: 1;
}

.team-name {
  margin-bottom: -2px;
}

.leaderboards-head {
  background-color: #a3a3a3;
  color: #fff;
}

.flag-eur {
  order: 1;
  span {
    margin-left: 12px;
    @media (max-width: 400px) {
      width: 20px;
      height: 20px;
    }
  }
}

.team-person {
  line-height: 120%;
  @media (max-width: 460px) {
    font-size: 0.8rem;
  }
}

.team-lead-eur {
  order: 2;
}

.team-leader {
  order: 3;
}

.team-lead-usa {
  order: 4;
}

.flag-usa {
  order: 5;
  span {
    margin-right: 4px;
    @media (max-width: 400px) {
      width: 20px;
      height: 20px;
    }
  }
}

.center-xy {
  display: flex;
  justify-content: center;
  align-self: center;
}

.mp-head {
  // font-size: 1.1rem;

  img {
    max-width: 130px;
  }
}

.mp-body {
  font-size: 1.1rem;
  .col-flag {
    padding-right: 0;
    margin-left: -7px;
    margin-bottom: 5px;
  }

  .col-players {
    line-height: 100%;
  }

  .color-standing {
    font-size: 2.5rem;
    letter-spacing: -2px;
  }

  .team-thru {
    font-size: 1.2rem;
  }

  .team-leader-up {
    line-height: 180%;
  }
}

.mp-wrapper {
  font-size: 16px;
  h5 {
    font-weight: 700;
    font-size: 13px;
  }
}

.mp-player {
  margin-bottom: 5px;
}

.mp-round-name {
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  font-weight: 700;
}

.mp-hole {
  text-transform: capitalize;
  margin-top: 3px;
  font-size: 13px;
}

.player-row-information {
  // position: sticky;
  // top: 32px;
  // z-index: 9;

  //   display: flex;
  font-size: 12px;
  background: #a3a3a3;

  svg {
    width: 20px;
    height: 20px;
  }

  @media (max-width: 576px) {
    .first-section {
      margin-bottom: -7px;
    }
  }

  .green-dot {
    margin-bottom: 1px;
    width: 15px;
    height: 15px;
  }
}

.player-col {
  display: flex;
  line-height: 100%;
  padding: 7px 0;
  align-items: center;
  gap: 3px;

  &:nth-child(1) {
    gap: 6px;
  }
}
